import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SubNavigationMenu } from 'ui/SubNavigationMenu';

import {
  QualificationStatusBadge,
  useProofOfFundsStatus,
} from 'components/QualificationStatus';
import { useProfileStatus } from 'components/QualificationStatus/hooks/useProfileStatus';

import { routePaths } from 'router/routePaths';

export const QualificationScreenMenu = memo(() => {
  const { t } = useTranslation();
  const profileStatus = useProfileStatus();
  const fundsStatus = useProofOfFundsStatus();

  return (
    <SubNavigationMenu.Menu
      css={{ mt: '$4' }}
      data-testid="qualification-submenu"
    >
      <SubNavigationMenu.MenuItem
        icon={<FontAwesomeIcon icon={regular('id-badge')} size="lg" />}
        as={NavLink}
        to={routePaths.qualificationProfile}
        extraChildren={<QualificationStatusBadge status={profileStatus} />}
      >
        {t('QualificationScreenMenu:profile_section')}
      </SubNavigationMenu.MenuItem>
      <SubNavigationMenu.MenuItem
        icon={<FontAwesomeIcon icon={regular('dollar-sign')} size="lg" />}
        as={NavLink}
        to={routePaths.qualificationProofOfFunds}
        extraChildren={<QualificationStatusBadge status={fundsStatus} />}
      >
        {t('QualificationScreenMenu:proof_of_funds_section')}
      </SubNavigationMenu.MenuItem>
    </SubNavigationMenu.Menu>
  );
});

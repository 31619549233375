import { IConfig } from './config.interface';

const ENV = process.env;

const config: IConfig = {
  AUTH0_DOMAIN: ENV.REACT_APP_AUTH0_DOMAIN as string,
  AUTH0_CLIENT_ID: ENV.REACT_APP_AUTH0_CLIENT_ID as string,
  AUTH0_AUDIENCE: ENV.REACT_APP_AUTH0_AUDIENCE as string,
  API_BASE_URL: ENV.REACT_APP_API_BASE_URL as string,
  ENVIRONMENT: ENV.REACT_APP_ENVIRONMENT as string,

  // Sentry configuration
  SENTRY_DSN: ENV.REACT_APP_SENTRY_DSN as string,
  SENTRY_ORGANIZATION: 'boopos',
  SENTRY_PROJECT: 6436349,

  OPT_OUT_COOKIE: ENV.REACT_APP_OPT_OUT_COOKIE_NAME as string,

  // Tracking tools
  SEGMENT_KEY: ENV.REACT_APP_SEGMENT_KEY as string,
  SEGMENT_CDN: ENV.REACT_APP_SEGMENT_CDN as string,
  SEGMENT_API: ENV.REACT_APP_SEGMENT_API as string,

  POSTHOG_TOKEN: ENV.REACT_APP_POSTHOG_TOKEN as string,
  POSTHOG_API_HOST: ENV.REACT_APP_POSTHOG_API_HOST as string,

  APP_VERSION: ENV.REACT_APP_APP_VERSION as string,

  TERMS_AND_CONDITIONS_URL: 'https://www.boopos.com/terms-conditions',
  PRIVACY_POLICY_URL: 'https://www.boopos.com/privacy-policy',
  PNL_SAMPLE_URL:
    'https://boopos-public.s3.eu-west-1.amazonaws.com/assets/PL-Sample.xlsx',
  COHORTS_SAMPLE_URL:
    'https://boopos-public.s3.eu-west-1.amazonaws.com/assets/Cohorts-Sample.xlsx',
  COHORTS_ARTICLE_URL:
    'https://www.boopos.com/all-post/what-is-a-cohort-analysis-in-saas-businesses',
  QUALIFICATION_MEETING_URL: 'https://meetings.boopos.com/',
  SELLER_MEETING_URL:
    'https://engagement.boopos.com/meetings/boopos/valuations-and-insights',
  USE_CONNECTORS_SANDBOX: ENV.REACT_APP_USE_CONNECTORS_SANDBOX === 'true',
  STRIPE_PK: ENV.REACT_APP_STRIPE_PK as string,
  GOOGLE_MAPS_API_KEY: ENV.REACT_APP_GOOGLE_MAPS_API_KEY as string,
  ORIGINATION_EMAIL: 'origination@boopos.com',
  LINKEDIN_SELF_PROFILE_URL: 'https://www.linkedin.com/in/',
};

export default config;

import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';

import { ContentCellDetail } from 'ui/ContentCellDetail';
import { ListCard } from 'ui/ListCard';

import { AmountOrEmpty } from 'components/AmountOrEmpty';
import { ForSaleFavouriteButton } from 'components/ForSaleDealFlowButton';
import { ListingTinyChart } from 'components/ListingTinyChart';

import { ListingForSaleReduced } from 'lib/types';

import { routePaths } from 'router/routePaths';

import { ForSaleCardFeedback } from './ForSaleCardFeedback';
import { ForSaleTags } from './ForSaleTags';
import { ListingLoanAmount } from './ListingLoanAmount';

export interface ForSaleCardProps {
  listing: ListingForSaleReduced;
  onSuccess: () => Promise<void>;
}

export const ForSaleCard = forwardRef<HTMLDivElement, ForSaleCardProps>(
  (props, ref) => {
    const { t } = useTranslation();
    const { listing, onSuccess } = props;

    if (!listing) {
      return null;
    }

    const to = generatePath(routePaths.forSaleDetail, {
      listingId: listing.id,
    });

    return (
      <ListCard.Card
        ref={ref as any}
        as={Link}
        to={to}
        data-testid="for-sale-card"
      >
        <ListCard.TitleWithAction>
          <ListCard.TitleContainer>
            <ListCard.Title>{listing?.title}</ListCard.Title>
            <ListCard.TagsContainer>
              <ForSaleTags listing={listing} />
            </ListCard.TagsContainer>
          </ListCard.TitleContainer>
          <ListCard.TitleAction>
            <ForSaleFavouriteButton listing={listing} onSuccess={onSuccess} />
          </ListCard.TitleAction>
        </ListCard.TitleWithAction>
        <ListCard.ValuesGrid>
          <ListCard.ValueItem>
            <ContentCellDetail.Label>
              {t('ForSaleCard:business_price')}
            </ContentCellDetail.Label>
            <ContentCellDetail.Value>
              <AmountOrEmpty amount={listing?.askPrice} />
            </ContentCellDetail.Value>
          </ListCard.ValueItem>
          <ListCard.ValueItem>
            <ContentCellDetail.Label>
              {t('ForSaleCard:ttm_revenue')}
            </ContentCellDetail.Label>
            <ContentCellDetail.Value css={{ display: 'flex', gap: '$2' }}>
              <AmountOrEmpty amount={listing?.revenue} />
              <ListingTinyChart revenueHistory={listing.revenueHistory} />
            </ContentCellDetail.Value>
          </ListCard.ValueItem>
          <ListCard.ValueItem>
            <ContentCellDetail.Label>
              {t('ForSaleCard:ttm_profit')}
            </ContentCellDetail.Label>
            <ContentCellDetail.Value>
              <AmountOrEmpty amount={listing?.profit} />
            </ContentCellDetail.Value>
          </ListCard.ValueItem>
          <ListingLoanAmount listing={listing} />
        </ListCard.ValuesGrid>
        <ForSaleCardFeedback listing={listing} />
      </ListCard.Card>
    );
  }
);

import { JSX } from 'react';

import {
  IUseAccountConnector,
  MerchantType,
  Merchants,
} from './AccountConnectors.interface';
import { BankLink } from './bank/BankLink';
import { CustomConnectionLink } from './custom/CustomConnectionLink';
import { ErpLink } from './erp/ErpLink';
import { RutterLink } from './rutter/RutterLink';
import { StripeLink } from './stripe/StripeLink';

export const MerchantInitiatorComponent: {
  [key in Merchants]?: (props: IUseAccountConnector) => JSX.Element;
} = {
  [Merchants.amazon]: CustomConnectionLink,
  [Merchants.shopify]: CustomConnectionLink,
  [Merchants.paddle]: CustomConnectionLink,
  [Merchants.woocommerce]: RutterLink as any,
  [Merchants.square]: RutterLink as any,
  [Merchants.magento]: RutterLink as any,
  [Merchants.ebay]: RutterLink as any,
  [Merchants.stripe]: StripeLink,
  [Merchants.xero]: ErpLink,
  [Merchants.sage]: ErpLink,
  [Merchants.quickbooks]: ErpLink,
  [Merchants.zoho]: ErpLink,
  [Merchants.bank]: BankLink,
};

export const MERCHANTS_LIST: [keyof typeof Merchants, any][] = [
  [Merchants.amazon, CustomConnectionLink],
  [Merchants.shopify, CustomConnectionLink],
  [Merchants.paddle, CustomConnectionLink],
  [Merchants.woocommerce, RutterLink],
  [Merchants.square, RutterLink],
  [Merchants.magento, RutterLink],
  [Merchants.ebay, RutterLink],
  [Merchants.stripe, StripeLink],
];

export const ERP_LIST: [keyof typeof Merchants, any][] = [
  [Merchants.xero, ErpLink],
  [Merchants.sage, ErpLink],
  [Merchants.quickbooks, ErpLink],
  [Merchants.zoho, ErpLink],
];

export const MerchantsByType = {
  [MerchantType.revenueSource]: MERCHANTS_LIST,
  [MerchantType.erp]: ERP_LIST,
};

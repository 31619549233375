import qs from 'query-string';
import { useCallback } from 'react';

import {
  IUseAccountConnector,
  IUseAccountConnectorReturn,
} from 'components/AccountConnectors/AccountConnectors.interface';
import { useSharedHandlers } from 'components/AccountConnectors/useSharedHandlers';

import api from 'lib/api';
import { defaultWindowFeatures, parseWindowFeatures } from 'lib/utils';

import { routePaths } from 'router/routePaths';

export const useErpLink = ({
  provider,
  businessId,
  onSuccess,
}: IUseAccountConnector): IUseAccountConnectorReturn => {
  const { handleSuccess, setWindowRef, addError, startConnection } =
    useSharedHandlers({ provider, businessId, onSuccess });

  const open = useCallback(async () => {
    try {
      startConnection();
      const { data } = await api.initMerchantBusinessConnection(
        provider,
        businessId
      );

      const backParams = 'statusCode={statusCode}&errormessage={errorMessage}';
      const back = `${window.location.protocol}//${window.location.host}${routePaths.callbackErp}?${backParams}`;

      const params = {
        back,
      };

      const url = `${data.url}?${qs.stringify(params)}`;

      window.erpCallback = handleSuccess;
      setWindowRef(
        window.open(
          url,
          `connect-${provider}`,
          parseWindowFeatures(defaultWindowFeatures)
        )
      );
    } catch (e) {
      addError();
    }
  }, [
    setWindowRef,
    handleSuccess,
    businessId,
    provider,
    startConnection,
    addError,
  ]);

  return { open, ready: true, error: null };
};

import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { NavigationCard } from 'ui/NavigationCard';

import { Avatar } from 'components/MainMenu/Nav.styled';
import {
  OnboardingHeader,
  OnboardingLayout,
} from 'components/OnboardingLayout';

import { AuthParamsBuyerTargets } from 'lib/auth/authParams';
import { useEffectOnce } from 'lib/hooks/useEffectOnce';
import { usePreferences } from 'lib/hooks/usePreferences';
import { BuyerTarget, PreferenceType } from 'lib/types';

import { routePaths } from 'router/routePaths';

import { OnboardingLocationState } from 'screens/LandingScreens/useOnboardingState';

const BuyerTargetMapping = {
  [AuthParamsBuyerTargets.specific]: BuyerTarget.specific,
  [AuthParamsBuyerTargets.none]: BuyerTarget.none,
};

export const OnboardingBuyTargetScreen = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation() as { state: OnboardingLocationState };
  const { setPreference } = usePreferences();

  const navigateWithState = useCallback(
    (state?: OnboardingLocationState) => {
      const newState = { ...(location?.state ?? {}), ...(state ?? {}) };
      navigate(routePaths.onboardingProfileBuy, { state: newState });
    },
    [navigate, location?.state]
  );

  const onSpecificTargetClick = useCallback(() => {
    setPreference(PreferenceType.buyerTarget, { target: BuyerTarget.specific });
    navigateWithState({
      nextScreenOverwrite: routePaths.dealFlow,
    });
  }, [navigateWithState, setPreference]);

  const onNotSpecificTargetClick = useCallback(() => {
    setPreference(PreferenceType.buyerTarget, { target: BuyerTarget.none });
    navigateWithState({
      nextScreenOverwrite: routePaths.forSale,
    });
  }, [navigateWithState, setPreference]);

  const autoSetBuyerTargetState = location?.state?.autoSetBuyerTarget;

  const onAutoSetBuyerTarget = useCallback(() => {
    const target =
      !!autoSetBuyerTargetState && BuyerTargetMapping[autoSetBuyerTargetState];

    if (!target) {
      return;
    }

    setPreference(PreferenceType.buyerTarget, { target });
    navigateWithState();
  }, [autoSetBuyerTargetState, navigateWithState, setPreference]);

  useEffectOnce(() => {
    if (!autoSetBuyerTargetState) return;
    onAutoSetBuyerTarget();
  });

  return (
    <OnboardingLayout.Wrapper
      data-testid="onboarding-buy-target-screen"
      verticalSpacing="xl"
    >
      <OnboardingHeader.Wrapper>
        <OnboardingHeader.Steps>
          {t('OnBoardingNavigation:greeting')}
        </OnboardingHeader.Steps>
        <OnboardingHeader.Title>
          {t('OnboardingBuyTarget:title')}
        </OnboardingHeader.Title>
      </OnboardingHeader.Wrapper>
      <OnboardingLayout.Content>
        <OnboardingLayout.ContentWrapper>
          <NavigationCard
            title={t('OnboardingBuyTarget:specific_target')}
            description=""
            onClick={onSpecificTargetClick}
            data-testid="specific-target-button"
            variant="light"
            icon={
              <Avatar.AvatarCrop>
                <FontAwesomeIcon icon={solid('coins')} size="lg" />
              </Avatar.AvatarCrop>
            }
          />
          <NavigationCard
            title={t('OnboardingBuyTarget:not_specific_target')}
            description=""
            onClick={onNotSpecificTargetClick}
            data-testid="not-specific-target-button"
            variant="light"
            icon={
              <Avatar.AvatarCrop>
                <FontAwesomeIcon icon={solid('bullseye-arrow')} size="lg" />
              </Avatar.AvatarCrop>
            }
          />
        </OnboardingLayout.ContentWrapper>
      </OnboardingLayout.Content>
    </OnboardingLayout.Wrapper>
  );
};

import { useCallback } from 'react';
import { mutate } from 'swr';

import { apiV2 } from 'lib/api';
import { IPreferences, PreferenceType } from 'lib/types';

export const usePreferences = () => {
  const setPreference = useCallback(
    async (
      preferenceName: PreferenceType,
      value: IPreferences[typeof preferenceName]
    ) => {
      const body = {
        newValues: value,
      };
      await apiV2.updatePreferences(preferenceName, body);
      await mutate(apiV2.getMe());
    },
    []
  );

  return { setPreference };
};

import { Box } from '@boopos/design-system';

import { InvestmentCriteriaBanner } from 'components/InvestmentCriteriaBanner';
import { useInvestmentCriteriaBanner } from 'components/InvestmentCriteriaBanner/useInvestmentCriteriaBanner';
import {
  QualificationStatusApi,
  useQualificationStatus,
} from 'components/QualificationStatus';

export const ForSaleInvestmentCriteriaBanner = () => {
  const { isInvestmentCriteriaCompleted } = useInvestmentCriteriaBanner();
  const { status } = useQualificationStatus() || {};
  const isUserQualified = status === QualificationStatusApi.QUALIFIED;

  if (!isUserQualified || isInvestmentCriteriaCompleted) {
    return null;
  }

  return (
    <Box css={{ mt: '1.6rem' }}>
      <InvestmentCriteriaBanner />
    </Box>
  );
};

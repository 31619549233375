import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, createSearchParams } from 'react-router-dom';

import { Button } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Merchants, ProviderImage } from 'components/AccountConnectors';
import { DeleteConfirmationAction } from 'components/ConfirmationModal';

import api from 'lib/api';

import { IAccountCard } from './AccountCard.interface';
import {
  AccountDetails,
  AccountName,
  CardWrapper,
  DeleteWrapper,
  ImageWrapper,
} from './AccountCard.styles';

export const AccountCard = ({
  account,
  businessId,
  onRemove,
  pendingConnection,
  addIntegrationPath,
  ...props
}: IAccountCard) => {
  const { t } = useTranslation();
  const { name, provider, externalId } = account;

  const handleDisconnect = useCallback(async () => {
    try {
      await api.merchantsDisconnectAccount({
        provider,
        businessId,
        externalId,
      });
      onRemove && onRemove();
    } catch (err) {}
  }, [onRemove, businessId, provider, externalId]);

  const searchParams = createSearchParams({ provider }).toString();

  return (
    <CardWrapper {...props} pendingConnection={pendingConnection}>
      <ImageWrapper>
        {provider !== Merchants.bank ? (
          <ProviderImage provider={provider} />
        ) : (
          <div data-testid="bank-image">
            <FontAwesomeIcon icon={regular('building-columns')} size="2xl" />
          </div>
        )}
      </ImageWrapper>
      {pendingConnection ? (
        <Button
          as={Link}
          to={{ pathname: addIntegrationPath, search: searchParams }}
        >
          {t('AccountCard:connect')}
        </Button>
      ) : (
        <DeleteWrapper>
          <DeleteConfirmationAction
            title={t('AccountCard:delete_confirmation')}
            onDelete={handleDisconnect}
          >
            <Button
              variant="secondary"
              rounded
              onClick={handleDisconnect}
              data-testid="disconnect-account-button"
              leftIcon={<FontAwesomeIcon icon={regular('trash')} size="lg" />}
            />
          </DeleteConfirmationAction>
        </DeleteWrapper>
      )}

      {!pendingConnection ? (
        <AccountDetails>
          <div>{t('AccountCard:account_name')}</div>
          <AccountName>{name}</AccountName>
        </AccountDetails>
      ) : null}
    </CardWrapper>
  );
};

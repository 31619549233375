import React from 'react';

import { sliceStartAndFinish } from 'lib/format/literal/sliceStartAndFinish';

import { ERP_LIST, Merchants } from 'components/AccountConnectors';
import { Props } from './ConnectedAccountsDetailCell.interface';
import { AccountStyles } from './Styles';


export const AccountHint = ({ account }: Props) => {
  if (ERP_LIST.some(([merchant]) => merchant === account.provider)) {
    return null;
  }

  if (account.provider === Merchants.bank) {
    return (
      <AccountStyles.Hint>
        | {sliceStartAndFinish(account.name)}
      </AccountStyles.Hint>
    );
  }

  return <AccountStyles.Hint>| {account.name}</AccountStyles.Hint>;
};
import { Navigate } from 'react-router-dom';

import { config } from '@boopos/design-system';

import { RenderOrNavigate } from 'components/RenderOrNavigate';

import { routePaths } from 'router/routePaths';

import { AccountScreen } from 'screens/AccountScreen';
import { InvestmentCriteriaProfileScreen } from 'screens/InvestmentCriteriaProfileScreen';
import { NotificationsScreen } from 'screens/NotificationsScreen';
import { ProfileScreen } from 'screens/ProfileScreen';
import { ProofOfFundsScreen } from 'screens/ProofOfFundsScreen';
import { QualificationScreen } from 'screens/QualificationScreen';

export const accountRoutes = {
  path: routePaths.account,
  element: (
    <RenderOrNavigate
      basePath={routePaths.account}
      mediaQuery={config.media.bp2}
      navigateTo={routePaths.accountQualification}
      component={<AccountScreen />}
    />
  ),
  children: [
    {
      path: routePaths.accountProfile,
      element: <Navigate to={routePaths.qualificationProfile} />,
    },
    {
      path: routePaths.accountNotifications,
      element: <NotificationsScreen />,
    },
    {
      path: routePaths.accountInvestmentCriteria,
      element: <InvestmentCriteriaProfileScreen />,
    },
    {
      path: routePaths.accountQualification,
      element: (
        <RenderOrNavigate
          basePath={routePaths.accountQualification}
          mediaQuery={config.media.bp2}
          navigateTo={routePaths.qualificationProfile}
          component={<QualificationScreen />}
        />
      ),
      children: [
        {
          path: routePaths.qualificationProfile,
          element: <ProfileScreen />,
        },
        {
          path: routePaths.qualificationProofOfFunds,
          element: <ProofOfFundsScreen />,
        },
      ],
    },
  ],
};
